import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Mypage } from '@features/mypage';
import { Reset } from '@features/mypage/Reset';

const ResetPage = () => (
  <Layout>
    <Helmet>
      <title>マイページ | パスワード変更 | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <Mypage>
      <Reset />
    </Mypage>
  </Layout>
);

export default ResetPage;
