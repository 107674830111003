import React, { FC } from 'react';

import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { ErrorMessage } from '@components/typographies/error';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { useCertification } from '@features/reset-password/hooks/useCertification';
import { useCertificationPasswordForm } from '@features/reset-password/hooks/useCertificationPasswordForm';

export const Reset: FC = () => {
  const { certificationPassword, error, isLoading } = useCertification();
  const { data, handleInputChange, handleSubmit, errors } = useCertificationPasswordForm();

  return (
    <Block width="100%" md={{ padding: '30px' }}>
      <Heading2 fontSize="24px" lh="29px" mb="22px">
        会員情報の確認・変更
      </Heading2>
      <Heading2 mb="17px">パスワード変更</Heading2>
      <Paragraph lh="17px" my="17px">
        パスワード変更のためのURLをメールアドレス宛に送信します。
      </Paragraph>
      <form onSubmit={handleSubmit(certificationPassword)}>
        <Flex
          alignItems="flex-start"
          direction="column"
          margin="0 0 24px 0"
          md={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Paragraph
            width="fit-content"
            lh="17px"
            md={{ whiteSpace: 'nowrap', paddingRight: '70px' }}
          >
            メールアドレス
          </Paragraph>
          <Input
            borderRadius="5px"
            hasBorder
            value={data.email}
            onChange={handleInputChange('email')}
          />
        </Flex>
        <Flex direction="column" jc="center" margin="24px 0 100px 0">
          {error?.getError() && <ErrorMessage>{error.getError()}</ErrorMessage>}
          {errors.email?._errors[0] && <ErrorMessage>{errors.email?._errors[0]}</ErrorMessage>}
          <Button
            type="submit"
            width="183px"
            height="35px"
            radius="5px"
            bg="gray2"
            border="1px solid var(--color-border-gray2)"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'メールを送信する'}
          </Button>
        </Flex>
      </form>
    </Block>
  );
};
